import React from "react";
import { Flex, Box, Text } from "@chakra-ui/core";
import Socials from "./Socials";
import { css } from "@emotion/core";
import PropTypes from "prop-types";

export default function Footer({ post }) {
  return (
    <footer>
      <Box mt="2rem">
        <hr />
        <Flex
          mt={3}
          justifyContent={[
            "space-between",
            "space-between",
            post ? "space-between" : "center",
          ]}
          alignItems="center"
        >
          <Text color="brand.oxford">
            ©{" "}
            <a href="https://aawaaz.co">
              <span
                css={css`
                  &:hover {
                    text-decoration: underline;
                  }
                `}
              >
                Aawaaz
              </span>
            </a>
          </Text>
          <Socials w={20} show={post ? "flex" : "none"} />
        </Flex>
      </Box>
    </footer>
  );
}

Footer.defaultProps = {
  post: false,
};

Footer.propTypes = {
  post: PropTypes.bool.isRequired,
};
