import React from "react";
import { Flex } from "@chakra-ui/core";
import fb from "../../static/images/other/facebook logo.png";
import ig from "../../static/images/other/ig logo.png";
import li from "../../static/images/other/linkedin logo.png";
import PropTypes from "prop-types";

export default function Socials({ w, show }) {
  return (
    <Flex
      width={["28vw", 40]}
      display={["flex", "flex", show]}
      justifyContent={["space-between"]}
      alignItems="center"
    >
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/aawaazeducationservices/"
      >
        <img alt="Facebook Link" src={fb} width={w} height={w} />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/aawaaz_edu/?hl=en"
      >
        <img alt="Instagram Link" src={ig} width={w} height={w} />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.linkedin.com/company/aawaaz-education-services"
      >
        <img alt="LinkedIn Link" src={li} width={w} height={w} />
      </a>
    </Flex>
  );
}

Socials.propTypes = {
  w: PropTypes.number.isRequired,
  show: PropTypes.string.isRequired,
};
