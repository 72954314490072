import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { url } from "../../const";

function SEO({ description, lang, meta, title, authorName, image, url }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );
  const metaDescription = description || site.siteMetadata.description;
  const author = authorName || site.siteMetadata.author;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: "website",
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: "og:image",
          content: image,
        },
        {
          property: "og:url",
          content: url,
        },
      ].concat(meta)}
    >
      {/* <link
        rel="preload"
        href="/fonts/Kollektif.ttf"
        as="font"
        type="font/ttf"
      /> */}
      <link
        rel="preload"
        // rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&family=Raleway&display=swap"
        as="style"
        onLoad="this.onload=null;this.rel='stylesheet'"
      />
      {/* <noscript>
        <link
          href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&family=Raleway&display=swap"
          rel="stylesheet"
          type="text/css"
        />
      </noscript> react-helmet error */}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  authorName: "Aawaaz",
  image:
    "https://media.licdn.com/dms/image/C510BAQE1j6ztZzs44g/company-logo_200_200/0?e=2159024400&v=beta&t=k1GmdsDWZ0KbDCf6xriLT9IWUQk77E3_F5ELmDnQFFc",
  url,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  authorName: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
};

export default SEO;
